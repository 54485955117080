html {
  font-size: 16px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: .5em;
  color: #333;
  background: #f8f8f8
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080
}

.hljs-string,
.hljs-doctag {
  color: #d14
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold
}

.hljs-subst {
  font-weight: normal
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal
}

.hljs-regexp,
.hljs-link {
  color: #009926
}

.hljs-symbol,
.hljs-bullet {
  color: #990073
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3
}

.hljs-meta {
  color: #999;
  font-weight: bold
}

.hljs-deletion {
  background: #fdd
}

.hljs-addition {
  background: #dfd
}

.hljs-emphasis {
  font-style: italic
}

.hljs-strong {
  font-weight: bold
}
@charset "UTF-8";

/**
 * AUTHOR: HU
 */

/* ================== GLOBAL */
html, body {
  height: 100%;
}
#root {
  height: 100%;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 20px;
  font-weight: 500;
}
section {
  background-color: rgba(255, 255, 255, 0.95);
}
/* ================== REUSABLE PIECES */
.align-center {
  text-align: center;
}
.without-list-style {
  list-style: none;
  padding-left: 0;
}
.underline {
  display: inline-block;
  position: relative;
  color: #323232;
  text-decoration: none;
  margin-bottom: 4px;
}
.underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #323232;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
}
.underline:hover::after {
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.cursor-pointer {
  cursor: pointer;
}

/* ================= ANIMATION */
@-webkit-keyframes glitch {
  0% {
    /* transform: translate3d(2vw, -3vh, 0px); */
    -webkit-clip-path: polygon(0px 20%, 100% 20%, 100% 21%, 0px 21%);
            clip-path: polygon(0px 20%, 100% 20%, 100% 21%, 0px 21%);
  }
  2% {
    -webkit-clip-path: polygon(0px 33%, 100% 33%, 100% 33%, 0px 33%);
            clip-path: polygon(0px 33%, 100% 33%, 100% 33%, 0px 33%);
  }
  4% {
    -webkit-clip-path: polygon(0px 44%, 100% 44%, 100% 44%, 0px 44%);
            clip-path: polygon(0px 44%, 100% 44%, 100% 44%, 0px 44%);
  }
  5% {
    -webkit-clip-path: polygon(0px 50%, 100% 50%, 100% 20%, 0px 20%);
            clip-path: polygon(0px 50%, 100% 50%, 100% 20%, 0px 20%);
  }
  6% {
    -webkit-clip-path: polygon(0px 70%, 100% 70%, 100% 70%, 0px 70%);
            clip-path: polygon(0px 70%, 100% 70%, 100% 70%, 0px 70%);
  }
  7% {
    -webkit-clip-path: polygon(0px 80%, 100% 80%, 100% 80%, 0px 80%);
            clip-path: polygon(0px 80%, 100% 80%, 100% 80%, 0px 80%);
  }
  8% {
    -webkit-clip-path: polygon(0px 50%, 100% 50%, 100% 55%, 0px 55%);
            clip-path: polygon(0px 50%, 100% 50%, 100% 55%, 0px 55%);
  }
  9% {
    -webkit-clip-path: polygon(0px 70%, 100% 70%, 100% 80%, 0px 80%);
            clip-path: polygon(0px 70%, 100% 70%, 100% 80%, 0px 80%);
  }
  9.9% {
    /* transform: translate3d(2vw, -3vh, 0px); */
  }
  10%, 100% {
    /* transform: translate3d(0px, 0px, 0px); */
    -webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%);
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%);
  }
}
@keyframes glitch {
  0% {
    /* transform: translate3d(2vw, -3vh, 0px); */
    -webkit-clip-path: polygon(0px 20%, 100% 20%, 100% 21%, 0px 21%);
            clip-path: polygon(0px 20%, 100% 20%, 100% 21%, 0px 21%);
  }
  2% {
    -webkit-clip-path: polygon(0px 33%, 100% 33%, 100% 33%, 0px 33%);
            clip-path: polygon(0px 33%, 100% 33%, 100% 33%, 0px 33%);
  }
  4% {
    -webkit-clip-path: polygon(0px 44%, 100% 44%, 100% 44%, 0px 44%);
            clip-path: polygon(0px 44%, 100% 44%, 100% 44%, 0px 44%);
  }
  5% {
    -webkit-clip-path: polygon(0px 50%, 100% 50%, 100% 20%, 0px 20%);
            clip-path: polygon(0px 50%, 100% 50%, 100% 20%, 0px 20%);
  }
  6% {
    -webkit-clip-path: polygon(0px 70%, 100% 70%, 100% 70%, 0px 70%);
            clip-path: polygon(0px 70%, 100% 70%, 100% 70%, 0px 70%);
  }
  7% {
    -webkit-clip-path: polygon(0px 80%, 100% 80%, 100% 80%, 0px 80%);
            clip-path: polygon(0px 80%, 100% 80%, 100% 80%, 0px 80%);
  }
  8% {
    -webkit-clip-path: polygon(0px 50%, 100% 50%, 100% 55%, 0px 55%);
            clip-path: polygon(0px 50%, 100% 50%, 100% 55%, 0px 55%);
  }
  9% {
    -webkit-clip-path: polygon(0px 70%, 100% 70%, 100% 80%, 0px 80%);
            clip-path: polygon(0px 70%, 100% 70%, 100% 80%, 0px 80%);
  }
  9.9% {
    /* transform: translate3d(2vw, -3vh, 0px); */
  }
  10%, 100% {
    /* transform: translate3d(0px, 0px, 0px); */
    -webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%);
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%);
  }
}

@-webkit-keyframes glitch-shadow {
  0% {
    opacity: 1;
  }
  5.9% { 
    left: -4px;
    opacity: 0;
    z-index: 1;
  }
  11.9% {
    left: 10px;
  }
  12% {
    opacity: 1;
  }
  24%, 100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes glitch-shadow {
  0% {
    opacity: 1;
  }
  5.9% { 
    left: -4px;
    opacity: 0;
    z-index: 1;
  }
  11.9% {
    left: 10px;
  }
  12% {
    opacity: 1;
  }
  24%, 100% {
    opacity: 0;
    z-index: -1;
  }
}

/* ================= NAV */
nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  height: 60%;
}
nav h2 {
  position: relative;
}
nav h2:hover > span {
  -webkit-animation: glitch 6000ms linear 240ms infinite normal none running;
          animation: glitch 6000ms linear 240ms infinite normal none running;
}
nav h2:hover::after {
  z-index: -1;
  content: attr(data-text);
  position: absolute;
  top: 4px;
  left: -4px;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.35);
  background: transparent;
  -webkit-animation: glitch-shadow 6000ms linear 0s infinite normal none running;
          animation: glitch-shadow 6000ms linear 0s infinite normal none running;
}
nav a {
  margin-bottom: 10px;
}

/* ================= ROOT */
#root {
  position: relative;
  overflow: hidden;
  background-image: -webkit-linear-gradient(45deg,#b42738 20%,#d0ac28 0,#d0ac28 40%,#92a518 0,#92a518 60%,#488255 0, #488255 80%, #32364f 0);
  background-image: linear-gradient(45deg,#b42738 20%,#d0ac28 0,#d0ac28 40%,#92a518 0,#92a518 60%,#488255 0, #488255 80%, #32364f 0);
}
/* ================= FRAMES */
.frame {
  position: absolute;
  overflow: auto;
  height: 99%;
  width: 99%;
  border-left: 1.5px dashed #323232;
  border-top: 1.5px dashed #323232;
  -webkit-perspective: 500px;
          perspective: 500px;
}
.categories, .tags, .content, .about, .demos, .no-match, .loading {
  text-transform: uppercase;
  font-family: 'Dosis', sans-serif;
}
.categories, .tags, .content, .post, .about, .demos, .no-match, .loading {
  max-width: 1050px;
  margin: 0 auto;
  padding: 10vh 5vw;
}
.content .post-date {
  margin-right: 2vw;
}
/* ================= MARKDOWN */
.post {
  font-size: 14px;
  font-family: 'Dosis', sans-serif;
  letter-spacing: .04em;
  color: #323232;
}
.post pre {
  margin: .5em 0;
  padding: .4em .6em;
  line-height: 1.5;
  border-radius: 2px;
  background: #f8f8f8;
  overflow-x: auto;
}
.post code {
  background-color: #f8f8f8;
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: .2em .4em;
  font-family: Menlo,Monaco,Consolas,"Andale Mono","lucida console","Courier New",monospace;
}
