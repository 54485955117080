@charset "UTF-8";

/**
 * AUTHOR: HU
 */

/* ================== GLOBAL */
html, body {
  height: 100%;
}
#root {
  height: 100%;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 20px;
  font-weight: 500;
}
section {
  background-color: rgba(255, 255, 255, 0.95);
}
/* ================== REUSABLE PIECES */
.align-center {
  text-align: center;
}
.without-list-style {
  list-style: none;
  padding-left: 0;
}
.underline {
  display: inline-block;
  position: relative;
  color: #323232;
  text-decoration: none;
  margin-bottom: 4px;
}
.underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #323232;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform .25s ease-in-out;
}
.underline:hover::after {
  transform-origin: 0 50%;
  transform: scaleX(1);
}

.cursor-pointer {
  cursor: pointer;
}

/* ================= ANIMATION */
@keyframes glitch {
  0% {
    /* transform: translate3d(2vw, -3vh, 0px); */
    clip-path: polygon(0px 20%, 100% 20%, 100% 21%, 0px 21%);
  }
  2% {
    clip-path: polygon(0px 33%, 100% 33%, 100% 33%, 0px 33%);
  }
  4% {
    clip-path: polygon(0px 44%, 100% 44%, 100% 44%, 0px 44%);
  }
  5% {
    clip-path: polygon(0px 50%, 100% 50%, 100% 20%, 0px 20%);
  }
  6% {
    clip-path: polygon(0px 70%, 100% 70%, 100% 70%, 0px 70%);
  }
  7% {
    clip-path: polygon(0px 80%, 100% 80%, 100% 80%, 0px 80%);
  }
  8% {
    clip-path: polygon(0px 50%, 100% 50%, 100% 55%, 0px 55%);
  }
  9% {
    clip-path: polygon(0px 70%, 100% 70%, 100% 80%, 0px 80%);
  }
  9.9% {
    /* transform: translate3d(2vw, -3vh, 0px); */
  }
  10%, 100% {
    /* transform: translate3d(0px, 0px, 0px); */
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%);
  }
}

@keyframes glitch-shadow {
  0% {
    opacity: 1;
  }
  5.9% { 
    left: -4px;
    opacity: 0;
    z-index: 1;
  }
  11.9% {
    left: 10px;
  }
  12% {
    opacity: 1;
  }
  24%, 100% {
    opacity: 0;
    z-index: -1;
  }
}

/* ================= NAV */
nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  height: 60%;
}
nav h2 {
  position: relative;
}
nav h2:hover > span {
  animation: glitch 6000ms linear 240ms infinite normal none running;
}
nav h2:hover::after {
  z-index: -1;
  content: attr(data-text);
  position: absolute;
  top: 4px;
  left: -4px;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.35);
  background: transparent;
  animation: glitch-shadow 6000ms linear 0s infinite normal none running;
}
nav a {
  margin-bottom: 10px;
}

/* ================= ROOT */
#root {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(45deg,#b42738 20%,#d0ac28 0,#d0ac28 40%,#92a518 0,#92a518 60%,#488255 0, #488255 80%, #32364f 0);
}
/* ================= FRAMES */
.frame {
  position: absolute;
  overflow: auto;
  height: 99%;
  width: 99%;
  border-left: 1.5px dashed #323232;
  border-top: 1.5px dashed #323232;
  perspective: 500px;
}
.categories, .tags, .content, .about, .demos, .no-match, .loading {
  text-transform: uppercase;
  font-family: 'Dosis', sans-serif;
}
.categories, .tags, .content, .post, .about, .demos, .no-match, .loading {
  max-width: 1050px;
  margin: 0 auto;
  padding: 10vh 5vw;
}
.content .post-date {
  margin-right: 2vw;
}
/* ================= MARKDOWN */
.post {
  font-size: 14px;
  font-family: 'Dosis', sans-serif;
  letter-spacing: .04em;
  color: #323232;
}
.post pre {
  margin: .5em 0;
  padding: .4em .6em;
  line-height: 1.5;
  border-radius: 2px;
  background: #f8f8f8;
  overflow-x: auto;
}
.post code {
  background-color: #f8f8f8;
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: .2em .4em;
  font-family: Menlo,Monaco,Consolas,"Andale Mono","lucida console","Courier New",monospace;
}